import {ExibeMensagem} from "../components/shared/Message";
import errorCodes from "seb-graph-api-types/errorCodes";
import {GraphQLError} from "graphql/error";
import MUTATE_REFRESH_TOKEN from "../graphql/mutations/refresh-token";
import {ApolloClient, FetchResult, Observable} from "@apollo/client";

export const STORAGE_TOKEN_KEY = 'sebAccessToken';
export const STORAGE_REFRESH_TOKEN_KEY = 'sebRefreshToken';

export function dispatchGraphError(errors = [], onlyThrowOnAuthContext = false, operation = null, forward =null) {
  if (!onlyThrowOnAuthContext) return;
  const dispatch = (errors) => {
    errors.forEach(error => {
      const {
        extensions,
        message
      } = error;
      const {
        response,
        code
      } = extensions || {};
      const {
        body,
        status,
        statusText
      } = response || {};
      const level = status >= 500 ? '2' : '1';

      if (response) {
        if (body) {
          ExibeMensagem(body, level, true);
        } else if (statusText) {
          ExibeMensagem(statusText, level, true);
        }
      } else {
        switch (code) {
          case 'UNAUTHENTICATED':
              ExibeMensagem("Usuário não autenticado.", '2', true);
              // trigger msal login 
              // @ts-ignore
              window.msalInstance.loginRedirect();
            break;
          case errorCodes.SESSION_EXPIRED_ERROR:
            if (operation && operation.operationName === 'refreshToken') return;

            const observable = new Observable<FetchResult<Record<string, any>>>(
              (observer) => {
                (async () => {
                  try {
                    const accessToken = await refreshToken();

                    if (!accessToken) {
                      throw new GraphQLError('Empty AccessToken');
                    }

                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward && operation && subscriber && forward(operation).subscribe(subscriber);
                  } catch (err) {
                    observer.error(err);
                  }
                })();
              }
            );

            return observable;
          default:
            console.error("Erro não tratado: " + message);
        }
      }
    });
  }
  if ('graphQLErrors' in errors) {
    const {
      graphQLErrors,
    } = errors;
    dispatch(graphQLErrors);
  } else if (errors.length > 0) {
    dispatch(errors);
  }
}

const refreshToken = async () => {
  try {
    // @ts-ignore
    const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
    const refreshResolverResponse = await client.mutate<{
      refreshToken: any
    }>({
      mutation: MUTATE_REFRESH_TOKEN,
    });

    const accessToken = refreshResolverResponse.data?.refreshToken.accessToken;
    const refreshToken = refreshResolverResponse.data?.refreshToken.refreshToken;
    localStorage.setItem(STORAGE_TOKEN_KEY, accessToken || '');
    localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, refreshToken || '');
    return accessToken;
  } catch (err) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
  }
};