import {onError} from "@apollo/client/link/error";
import {ApolloClient, from, GraphQLRequest, InMemoryCache, makeVar} from "@apollo/client";
import {appConfig, graphApiConfig} from "../config";
import {BatchHttpLink} from "@apollo/client/link/batch-http";
import {setContext} from "@apollo/client/link/context";
import { dispatchGraphError } from "src/utils/dispatchGraphError";

export const STORAGE_TOKEN_KEY = 'sebAccessToken';
export const STORAGE_REFRESH_TOKEN_KEY = 'sebRefreshToken';

export const needRefreshToken = makeVar(false);

const create = () => {
  function isRefreshRequest(operation: GraphQLRequest) {
    return operation.operationName === 'refreshToken';
  }

  function returnTokenDependingOnOperation(operation: GraphQLRequest) {
    if (isRefreshRequest(operation))
      return localStorage.getItem(STORAGE_REFRESH_TOKEN_KEY) || '';
    else return localStorage.getItem(STORAGE_TOKEN_KEY) || '';
  }

  const authLink = setContext((operation, { headers }) => {
    let token = returnTokenDependingOnOperation(operation);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'Seb-System-Id': appConfig.appId
      },
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        const graphErrors = [...graphQLErrors];
        dispatchGraphError(graphErrors, true, operation, forward);
      }

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }
  );

  const httpLink = new BatchHttpLink({
    uri: graphApiConfig.uri,
    batchMax: 5, // No more than 5 operations per batch
    batchInterval: 100, // Wait no more than 20ms after first batched operation
  })

  const client =  new ApolloClient({
    ssrMode: false,
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false,
      resultCacheMaxSize: 0,
      resultCaching: false,
      canonizeResults: false,
    }),
    queryDeduplication: true,
    defaultOptions: {
      watchQuery: {
        initialFetchPolicy: 'no-cache',
        fetchPolicy: 'no-cache',
        refetchWritePolicy: 'overwrite',
        nextFetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
    assumeImmutableResults: false,
  });


  return client;
}

export default create
