import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function GetByIdMatriculaMovimentacao(idMatricula, tipoMovimentacao) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      permiteMovimentar
    },
    errors
  } = await client.query({
    query: gql`
        query PermiteMovimentar($idMatricula: String, $tipoMovimentacao: Int) {
          permiteMovimentar(idMatricula: $idMatricula, tipoMovimentacao: $tipoMovimentacao) {
            value {
              codigoCliente
              codigoMatricula
              codigoMatriculaErp
              idAluno
              idMatricula
              periodo
              servico
              status
              id
              unidade_Name
              unidade_Id
              servico_Name
              servico_Id
              anoLetivo_Name
              anoLetivo_Id
              estudante_Name
              estudante_Id
              responsavelContratual_Name
              responsavelContratual_Id
              turma_Name
              turma_Id
              curso_Name
              curso_Id
              segmento_Name
              segmento_Id
              periodo_Value
              status_Value
              razaoStatus_Value
              razaoStatus_Nome
              tipoMatricula_Value
              dataDaMatricula
              urlContrato
              nomeLandingPage
              evolucaoMatricula
              dataEvolucaoMatricula
              cpfResponsavelAceite
              iPdeOrigemAceite
              dataAceite
              valorReserva
              descontoBolsaEstudo
              descontoBolsaIrmaos
              descontoBolsaNova
              tipoMatricula {
                valor
                descricao
              }
              itensContrato
              responsavelFinanceiro
              dataUltimoPagamento
              valorUltimoPagamento
              adimplente
              dadosAluno {
                id
                nome
                email
                rg
                cpf
                dataNascimento
                urlImagemPerfil
                codigoErp
                codigoCliente
                razaoStatusValue
                statusValue
                razaoStatusNome
                statusNome
                cadastroPendente
                criadoEm
              }
              descricaoBoleto
              codigobarrasBoleto
              urlBoleto
              documentosValidados
              disponivelParaMatricular
              bloqueioColaborador
              bloqueioPedagogico
              bloqueioSistemico
              matriculaBloqueada
              msgMatriculaBloqueada
              dataDeCancelamento
              motivoDoCancelamento
              entradaPaga
              idMatriculaPai
              bloqueioContratacaoUnidade
              mensagemBloqueioUnidade
            }
            statusCode
            messages {
              text
              typeMessage
            }
            
          }
        }
        `,
    variables: {
      idMatricula,
      tipoMovimentacao,
    }
  })

  dispatchGraphError(errors);

  return permiteMovimentar;
}

export async function GetBolsaAtual(idMatricula) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
        getBolsaAtual
    },
    errors
  } = await client.query({
    query: gql`
        query GetBolsaAtual($idMatricula: ID!) {
          getBolsaAtual(idMatricula: $idMatricula) {
            value {
              matriculaId
              tipoDaBolsa
              percentualBolsa
              movimentacaoBolsaId
              hasMovimentacaoPendente
              movimentacaoMotivoAlteracao
              movimentacaoTipoDaBolsa
              movimentacaoPercentualBolsa
              nomeSolicitante
              descricao
              dataInicio
              dataTermino
            }
            messages {
              text
              typeMessage
            }
            statusCode
          }
        }
        `,
    variables: {
        idMatricula,
    }
  })

  dispatchGraphError(errors);

  return getBolsaAtual.value;
}



export async function SolicitaAltercaoBolsaAtual(request) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      solicitaAltercaoBolsaAtual
    },
    errors
  } = await client.mutate({
    mutation: gql`
      mutation SolicitaAltercaoBolsaAtual($request: SecretariaBolsaSolicitarMovimentacaoRequest) {
        solicitaAltercaoBolsaAtual(request: $request) {
          value {
            matriculaId
            solicitacaoBolsaId
            statusMovimentacao
          }
          messages {
            text
            typeMessage
          }
          statusCode
        }
      }
      `,
    variables: {
      request,
    }
  })

  dispatchGraphError(errors);

  return solicitaAltercaoBolsaAtual;
}

export async function GetListaTipoBolsa() {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      listTipoBolsasEstudo
    },
    error,
    errors
  } = await client.query({
    query: gql`
        query ListTipoBolsasEstudo {
          listTipoBolsasEstudo {
            statusCode
            messages {
              text
              typeMessage
            }
            value {
              id
              nome
              motivoBolsa {
                valor
                label
              }
            }
          }
        }
        `,
    variables: {}
  })
  if (error) {
    alert("Erro");
  }

  dispatchGraphError(errors);

  return listTipoBolsasEstudo.value;
}

export async function GetAllTemplateMovimentacaoAtivos(idUnidade, permiteMovimentarTodos) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      getAllTemplateMovimentacaoAtivos
    },
    error,
    errors
  } = await client.query({
    query: gql`
        query GetAllTemplateMovimentacaoAtivos($idUnidade: String, $permiteMovimentarTodos: Boolean) {
          getAllTemplateMovimentacaoAtivos(idUnidade: $idUnidade, permiteMovimentarTodos: $permiteMovimentarTodos) {
            tipoMovimentacao {
              valor
            }
          }
        }
    `,
    variables: {
      idUnidade,
      permiteMovimentarTodos
    }
  })
  if (error) {
    alert("Erro");
  }

  dispatchGraphError(errors);

  return getAllTemplateMovimentacaoAtivos;
}


export async function getTemplateMovimentacao(idUnidade, tipoMovimentacao) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      getTemplateMovimentacao
    },
    error,
    errors
  } = await client.query({
    query: gql`
        query GetTemplateMovimentacao($idUnidade: String, $tipoMovimentacao: Int) {
          getTemplateMovimentacao(idUnidade: $idUnidade, tipoMovimentacao: $tipoMovimentacao) {
            nome
            diaLimiteMes
            tipoMovimentacao {
              valor
            }
          }
        }
    `,
    variables: {
      idUnidade,
      tipoMovimentacao
    }
  })
  if (error) {
    alert("Erro");
  }

  dispatchGraphError(errors);

  return getTemplateMovimentacao;
}



export async function PermiteMovimentarAvista(idMatricula, idUnidade, tipoMovimentacao, tipoMovResponsavel) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      permiteMovimentarAVista
    },
    error,
    errors
  } = await client.query({
    query: gql`
        query PermiteMovimentarAVista($movFilter: PermiteMovimentarInput!) {
          permiteMovimentarAVista(movFilter: $movFilter) {
            value
            statusCode
            messages {
              text
              typeMessage
            }
          }
        }
        `,
    variables: {
      movFilter: {
        idMatricula,
        idUnidade,
        tipoMovimentacao,
        tipoMovimentacaoResponsavel: tipoMovResponsavel
      }
    }
  })
  if (error) {
    alert("Erro");
  }

  dispatchGraphError(errors);

  return permiteMovimentarAVista;
}
